import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class OnthSpot extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
              <h4 className="heading-h2 text-center"><span className="bluecolor">Why Choose</span> Coinsclone As Your Blockchain Development Company?</h4>
              <p className='pharagraph text-center'>Coinsclone is a well-experienced, leading Blockchain Development Company that can turn your blockchain aspirations into reality. As your reliable partner, we offer top-tier blockchain development services, fully customized to meet your unique requirements. With a track record of excellence, Coinsclone provides in-house blockchain development solutions serving across a wide range of industries.</p>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/blockchain/on-the-spot.webp"
                alt="On the spot hire blockchain developers"
                placeholder='none'
                width={500}
              />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">Our motto, "Empowering businesses today by adopting tomorrow’s technology" is reflected in every aspect of our services. With greater access to technological tools and insights into market trends, our seasoned blockchain developers focus on achieving your project’s end goals. Having successfully completed 100+ projects, and with several active projects, our company’s portfolio will have a strong say in the quality of our service. Join the list of satisfied clients who have experienced the Coinsclone’s effect. From concept to deployment, we're with you every step of the way, ensuring your project stands apart in this competitive landscape.
              </p>
              <b>Choose Coinsclone today to explore the ultimate potential of blockchain technology!</b>
              <div className='mt-4 d-flex flex-wrap justify-content-center on-the'>
                <p>On-Time Project Delivery.</p>
                <p>100% Secure And Scalable Solutions.</p>
                <p>24/7 Uninterrupted Support And Guidance.</p>
                <p>One Place Solution From Ideation to Implementation.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default OnthSpot