import React from 'react'
import ButtonStartfree from '../ButtonComponent/ButtonStartfree'
import { StaticImage } from 'gatsby-plugin-image';


class BannerSection extends React.Component {


  render() {  

    return (
      <section className="banner">
        <div className="container text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h1 className="main-heading">White Label NFT Marketplace</h1>
            <p className="sub-heading">Launch Your Own NFT Marketplace with Our White Label NFT Marketplace Solution!</p>
            <p className="sub-small mb-xs-0 mb-md-4">Our affordable, feature-rich white-label NFT marketplace software is tailored to deliver an exceptional user experience, support diverse NFT collections, and help your business achieve guaranteed ROI in the competitive NFT market.</p>
          </div>
          <div className="text-center mt-3 mb-4">
            <ButtonStartfree />
          </div>
          <div className="quick text-center d-none d-md-block">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/whitelabel-nft-marketplace-development.png"
                  alt="White Label NFT Marketplace Development"
                  width={977}
                />
          </div>
          
        </div>
      </section>
    )
  }
}

export default BannerSection