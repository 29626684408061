import React from 'react'



class OurVarious extends React.Component {


  render() {

    return (
      <section className="process-of block pt-100 mb-0">
            <div className="container">     
              <h2 className="heading-h2 text-center">Blockchain Development Platforms <span className="bluecolor">We Support</span> </h2>
              <p className="text-center">
              As a prominent blockchain development company, we create and customize blockchains as per your individual requirements. Keep your businesses updated with our custom blockchain development solutions that have been pushing many startups to succeed.
              </p>
              <div className="d-lg-flex add-one">
                <div className="square" >
                  <p className="head3">Ethereum</p>
                  <p className="pharagraph">
                  Ethereum is the most popular blockchain, known for security and scalability. Build a modernized decentralized platform in the Ethereum blockchain to stay ahead with future upgrades.
                  </p>
                </div>
                <div className="square">
                  <p className="head3">BNB Chain</p>
                  <p className="pharagraph">
                  BNB chain minimizes the gas fee limitations while offering high capacity. Leverage BNB’s best-in-class solutions to empower your enterprise in terms of high throughput and efficiency.
                  </p>
                </div>
                <div className="square" >
                  <p className="head3">Solana </p>
                  <p className="pharagraph">
                  Solana provides unmatched scalability to improve the platform's throughput at minimal costs. Set up your platform or DApp with Solana blockchain which is more efficient and price-effective.
                  </p>
                </div>
              </div>
              <div className="d-lg-flex add-two">
              <div className="square" >
                  <p className="head3">Avalanche</p>
                  <p className="pharagraph">
                  Avalanche is a three-chain network combinedly providing security, scalability, and higher efficiency than other blockchains. The Avalanche blockchain development solutions allow users to create several applications which results in better efficiency, compatibility, and speed.
                  </p>
                </div>
                <div className="square">
                  <p className="head3">Cosmos</p>
                  <p className="pharagraph">
                  Cosmos aims to provide secure and decentralized connectivity between various blockchains to level up the application use cases. Elevate your company's credibility with Cosmos blockchain solutions that allow interoperability between different blockchains while ensuring scalability.
                  </p>
                </div>
              </div>
              <div className="d-lg-flex add-three">
                <div className="square" >
                  <p className="head3">Polygon (Matic)</p>
                  <p className="pharagraph">
                  Polygon is an ideal blockchain that comes with layer 2 scalability solutions. Explore the competitive advantage of lower transaction fees and security with Polygon blockchain solutions.
                  </p>
                </div>
                <div className="square">
                  <p className="head3">Hyperledger</p>
                  <p className="pharagraph">
                  Hyperledger offers a custom-built blockchain platform development tailored to your enterprise policies, providing exceptional adaptability, and top-notch flexibility for your business needs.
                  </p>
                </div>
                <div className="square" >
                  <p className="head3">Cardano</p>
                  <p className="pharagraph">
                  Construct DApp, and NFT Marketplace with the Cardano - an open-source blockchain. Based on the PoS consensus, experience top-standard interoperability, and sustainability in your business.
                  </p>
                </div>
              </div>
            </div>
      </section>
    )
  }
}

export default OurVarious