import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



class UsecaseOf extends React.Component {


  render() {

    return (
      <div className='pt-100'>
      <section className="usecase mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor"> Use Case</span> of our</span>
                White Lable NFT Marketplace</h3>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center d-none d-md-table-cell" >
            <StaticImage
                            src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/usecase-of-whitelabel-nft.png"
                            alt="Use cases of White Label NFT Marketplace Software"
                            width={470}
                          />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph" ><b>Art auctions -</b> Online auctions are organized to enable digital artists to earn maximum profits on their creations.
              </p>
              <p className="pharagraph" ><b>Real estate market -</b> Real estate assets can be tokenized and then bought, sold, and traded without losing their intrinsic value, along with all the property rights.
              </p>
              <p className="pharagraph" ><b>Gaming assets -</b>  Gaming coins, gaming screenshots, and gaming tutorials can be sold through the readymade NFT Marketplace we provide.
              </p>
              <p className="pharagraph" ><b>Sports Memorable Events  -</b>  Videos of famous sports events can be converted into NFTs and traded at a high value.
              </p>
              <p className="pharagraph" ><b>Celebrity statements -</b> Many famous celebrity statements are being tokenized and traded on the custom-made NFT Marketplace.
              </p>
              <p className="pharagraph mb-0" ><b>Music selling -</b> Many musicians are utilizing NFT Marketplaces not just for copyright regulations, but also to sell them through such platforms.
              </p>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default UsecaseOf