import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react'


class IndustriesWe extends Component {


  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
            <h3 className="heading-h2 text-center"><span className="heading-h3"><span className="bluecolor">Industries</span> Enhanced by</span>
            Our Blockchain-Powered Solutions</h3>
            <p className="text-center">
            With our blockchain development solutions, we help you revolutionize and streamline various operations effortlessly. We empower businesses to thrive and strengthen the standards of excellence in their respective industrial fields.
            </p>
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills mw1140" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Finance
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Real Estate
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Healthcare
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Education
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  E-Commerce
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Entertainment
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Agriculture
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Supply chain
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Auto-Vehicles
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Travel & Tourism
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab11')} >
                  Insurance 
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab12')} >
                  Gaming Sector
                </li>
              </ul>
              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Finance</h4>
                        <p className="pharagraph">With our top-notch fintech services, we can transform the finance world by streamlining cross-border payments, enhancing fraud detection, and ensuring rapid real-time transaction transparency.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/blockchain/finance.webp"
                        alt="Finance image1"
                        width={455}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Real Estate</h4>
                        <p className="pharagraph">With our blockchain development expertise, we influence the real estate sector, by enabling seamless property transactions, making the ownership transfer process faster and more transparent.</p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/blockchain/real-estate.webp"
                        alt="Real Estate image1 "
                        width={465}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Healthcare
                        </h4>
                        <p className="pharagraph">We combine the forces to provide secure solutions for encrypted patient data and medical records management. Our developers are well-versed in creating blockchain-powered systems for medical authenticity.</p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/blockchain/healthcare.webp"
                        alt="Healthcare image1"
                        width={274}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Education</h4>
                        <p className="pharagraph">Our blockchain solutions streamline credential verification and student data management. Also, it can easily create decentralized platforms for learning progress tracking, all while enhancing global access to educational resources.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/blockchain/education.webp"
                        alt="Education image1"
                        width={386}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">E-Commerce</h4>
                        <p className="pharagraph">Blockchain in the e-comm sector enhances transaction security, reduces fraud, and ensures transparency in product tracking. It enables decentralized payment systems, and smart contract automation, making the entire process smoother and more reliable.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/blockchain/e-commerce.webp"
                        alt="E-Commerce image1"
                        width={313}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Entertainment</h4>
                        <p className="pharagraph">We could revolutionize the entertainment industry by protecting intellectual property, enabling transparent royalty distribution, and reducing piracy. Furthermore, content creators can directly monetize their work, ensuring fair compensation with enhanced security.</p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1">
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/blockchain/entertainment.webp"
                        alt="Entertainment"
                        width={412}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Agriculture</h4>
                        <p className="pharagraph">Our blockchain development services can improve transparency in the agri sector. The tech has the capability to improve payment systems, regulate supply chains, and offer a more secure way to manage transactions and share agricultural data.</p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/blockchain/agriculture.webp"
                        alt="Agriculture image1"
                        width={281}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Supply chain</h4>
                        <p className="pharagraph">Blockchain offers end-to-end transparency, traceability, and security within the supply chain. Our solutions help businesses reduce fraud, enhance efficiency, and improve trust among all parties involved in the ecosystem.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/blockchain/supply-chain.webp"
                        alt="Supply chain image1"
                        width={231}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Auto-Vehicles
                        </h4>
                        <p className="pharagraph">Our blockchain solutions can streamline easy vehicle history tracking and IoT Integration. With the growing use of self-driving vehicles, we can also create decentralized systems for efficient operations, while reducing the risks of hacks.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/blockchain/auto-vehicles.webp"
                        alt="Auto-Vehicles image1"
                        width={455}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Travel & Tourism</h4>
                        <p className="pharagraph">Our Blockchain solutions have a huge influence on the tourism sector with transparent booking systems, hotel management applications, immutable travel records, etc. It also supports identity verification for seamless and secure travel experiences.</p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/blockchain/travel-and-tourism.webp"
                        alt="Travel & Tourism image1"
                        width={220}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab11" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Insurance</h4>
                        <p className="pharagraph">Blockchain enhances the efficiency of the insurance industry by automating claims processing and reducing fraud. Smart contracts streamline policy management and enable quick claims approval, ensuring a trustworthy experience.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/blockchain/insurance.webp"
                        alt="Insurance image1"
                        width={314}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab12" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Gaming Sector</h4>
                        <p className="pharagraph">The gaming world can foresee a huge shift with their play-to-earn models, enabling players to own, trade, and monetize in-game assets securely. Decentralized gaming ecosystems further enhance this by enabling seamless trading and monetization of digital assets.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/blockchain/gaming.webp"
                        alt="Gaming Sector image1"
                        width={296}
                      />
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    );
  }
}

export default IndustriesWe