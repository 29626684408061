import React from 'react'


const Popular = () => {


  return (
    <section className="industries pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h3 className="heading-h2 text-center">Why Our Blockchain Development Is <span className="bluecolor">Significant For Businesses?</span>
            </h3>
            <p className="pharagraph head text-center">Get your expectations ready to incorporate the vast benefits of public and private blockchains into your business.
            </p>
        </div>
        <div className='row popular we-offer'>
            <div className='col-md-12 col-lg-3'>
                <h4 className="head3">Easily Marketable Product</h4>
                <p className='pharagraph'>Discover feasible blockchain solutions and test your business model with easily marketable products. These are checked, aligned with market trends, and created with expert guidance. Get a vibrant platform that is more compatible with future upgrades promising bright business scope.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h4 className="head3">Tailored Blockchain Services</h4>
                <p className='pharagraph'>Experience high-tech functionality with blockchain development Solutions tailored uniquely to meet your business needs. Lead the market competition with ready-to-go blockchain software that is compiled with in-depth analysis and backed with flaw-free performance and reliability.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h4 className="head3">Product Solutions</h4>
                <p className='pharagraph'>Improvise the traditional processes with our blockchain development services to maintain trust, overcome real-time challenges, build brand awareness, and eliminate efficiency lags. Prioritize our blockchain product solutions for practical use cases and take a lead role in the industry.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h4 className="head3">Concept Validation </h4>
                <p className='pharagraph'>Concept Validation aids in exploring the next-generation blockchain technology that aligns with your enterprise goals. Implementing prominent functionalities of our blockchain solutions enables wider business opportunities to transform your startup into a reputed organization.</p>
            </div>
        </div>
      </div>
    </section>
  )
}

export default Popular