import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">
      <Container>
        <h6 className="heading-h2 text-center">Frequently Asked Questions</h6>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                How to Hire the Right Blockchain Developers for your blockchain project?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">To hire the right blockchain developers, try partnering with a reputed blockchain development company. Assess their portfolio, expertise in platforms, smart contracts, and tech capability. Alongside checking the reviews and ensuring that the developers can meet your project goals is necessary.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                What is Enterprise Blockchain Development?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Enterprise blockchain development involves creating private and permissioned blockchain solutions specially tailored for businesses. It enhances operational efficiency, data security, and transparency, without any central user controls.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                How long does it take to build a Blockchain App?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">The time taken to build a blockchain app can vary based on various factors such as the complexity of the project, feature integration, geographical regulations, and other factors. But, on an average note, it would take between 4 - 7 weeks.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                What Are the Core Features of Blockchain Technology?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The core features of Blockchain technology include decentralization, immutability, transparency, Self Automation, consensus, and security, which helps in facilitating peer-to-peer transactions.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                How much do blockchain development services cost?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">The cost included in blockchain development services cannot be pinpointed specifically, as it varies depending on several factors. But, on an average note, it would cost around $15,000 to $50,000, based on the chosen service.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle"  eventKey="5">
                Do you provide Blockchain project Development Consulting?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">Yes, Coinsclone provides Blockchain project development consulting services. We have a team of experienced blockchain consultants who offer personalized consultation services, ensuring your project’s success from ideation to deployment.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '6' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('6')} className="panel-toggle"  eventKey="6">
                How will Coinsclone help in scaling blockchain solutions for growing businesses?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="6">
                <div className="panel-body card-body">Coinsclone offers tailored blockchain solutions with functionalities focusing on addressing scalability issues. Features like scalable architecture, seamless integration, and robust security, ensure that the businesses can adapt and grow efficiently in the dynamic blockchain ecosystem.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container> 

    </section>
  )
}

export default FaqSection