import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-0 mb-md-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>Blockchain</span> Development Company Offering Custom Solutions</h1>
                <p className="pharagraph">Partner with Coinsclone, your trusted blockchain development company to experience the full potential of blockchain technology. With our professional blockchain developers and superior blockchain development services, we bring your digital ideas into reality. Join us to empower your businesses to thrive in this evolving digital era.</p>
                <p>Let’s Build businesses for the future!!</p>
                <div className="cta-banner">
              <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
          </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/blockchain/blockchain-development-company.webp"
                alt="Blockchain development company"
                className='d-none d-md-block'
                width={493}
                imgClassName='m-auto'
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection