import React from "react"

class WhyChoose extends React.Component {

  render() {
    return (

      <section className="fine-box ethsmart pt-100 mb-0">
          <div className="container"> 
            <h2 className="heading-h2 text-center"><span className="bluecolor">Why Choose</span> Coinsclone For the DeFi Staking Platform?</h2>
            <p className="text-center mb-2">
            Coinsclone is a leading DeFi staking platform development company serving crypto startups for more than 7+ years. We are a team of blockchain experts who have vast experience in DeFi projects. Our DeFi staking platform solutions are future-ready and are highly optimized for your business needs. Apart from these, here are a few reasons that justify us as a perfect DeFi staking development company in the crypto space.
            </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square" >
                <h3>Blockchain Expertise</h3>
                <p className="pharagraph">
                With in-depth proficiency and years of experience in blockchain projects, Coinsclone has been offering industry-leading blockchain solutions. Our team’s expertise will be evident in DeFi staking development as we not only plan but also execute as intended.
                </p>
              </div>
              <div className="square" >
                <h3>Client-Centric Development</h3>
                <p className="pharagraph">
                We follow a client-centric DeFi staking platform development process where we prioritize your thoughts and business goals. As an investor, you are in line with the development flow and get directed towards your project’s success.
                </p>
              </div>
              <div className="square" >
                <h3>End-to-End Support</h3>
                <p className="pharagraph">
                Right from ideation to deployment, you will have end-to-end support with a well-experienced team of professionals. Furthermore, our streamlined DeFi staking development process will ensure your convenience throughout the process.
                </p>
              </div>
              <div className="square" >
                <h3>Affordable Solutions</h3>
                <p className="pharagraph">
                Our DeFi solutions are highly competitive considering the price and inbuilt features. Our price range and associated services are budget-friendly while preserving the quality and performance of the solution.
                </p>
              </div>
              <div className="square" >
                <h3>Proficiency in Tech stacks</h3>
                <p className="pharagraph">
                We prefer the most appropriate yet innovative tech stacks for DeFi staking development services. Our experts are proficient in extracting maximum outcomes from the platform by utilizing the tech stacks efficiently.
                </p>
              </div>
              <div className="square" >
                <h3>DeFI Coverage</h3>
                <p className="pharagraph">
                Our DeFi staking platform development has been the initiative of many thriving businesses in the crypto space. Our DeFi solutions have contributed to the DeFi space for wallets, tokens, exchanges, and many more.
                </p>
              </div>
            </div>
          </div>
      </section>
    )
  }
}

export default WhyChoose
