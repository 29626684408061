import React from "react"
import ButtonComponent from "../ButtonComponent/ButtonComponent"

class ProcessOf extends React.Component {

  render() {
    return (

      <section className="process-of pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">
            Our Crypto Launchpad
              <span className="bluecolor"> Development Process</span>
            </h2>
            <p className="text-center">
            As a pioneer in the digital realm, we follow the below-mentioned steps for top-notch crypto launchpad development. 
            </p>
            <div className="d-lg-flex one">
              <div className="square" >
                <h3 className="befor">Project Planning</h3>
                <p className="pharagraph">
                Before the development process, it is essential to outline the complete roadmap. Here, we will help you to frame the necessary criteria for crypto launchpad development. At this stage, we will estimate the cost, crucial resources, and time for development.
                </p>
              </div>
              <div className="square">
                <h3 className="befor">Market Analysis</h3>
                <p className="pharagraph">
                After the roadmap is prepared, the next step is to work on analyzing the current market. Our developers assist you in finding out the present successor, their strategies, website structure, popularity, and other criteria. Through this, you can get an idea and knowledge about the present scenario of the market.
                </p>
              </div>
              <div className="square" >
                <h3 className="befor">Whitepaper Creation</h3>
                <p className="pharagraph">
                Once you are ready with all the essential resources, requirements, and developer team, you can then draft a whitepaper. Here, our technical team will assist you in completing the whitepaper creation process. This helps budding startups get a detailed view of your launchpad’s vision and mission. 
                </p>
              </div>
            </div>
            <div className="d-lg-flex two">
              <div className="square" >
                <h3 className="befor">Crypto token creation</h3>
                <p className="pharagraph">
                In need to raise funds for your projects, you have to create a new digital token. Our blockchain developers are experts in creating tokens with unique features and functionalities. The token creation will be the root of all other crypto crowdfunding methods.
                </p>
              </div>
              <div className="square" >
                <h3 className="befor">Website Development</h3>
                <p className="pharagraph">
                The Next step is involving ourselves in the creation of a launchpad website development. This will be the potential platform where the admin and user gather to execute the crowdfunding process. Our team focuses on creating a launchpad that would be a safe platform for token distribution
                </p>
              </div>
            </div>
            <div className="d-lg-flex three">
              <div className="square" >
                <h3 className="befor">Framing Admin/User Dashboard</h3>
                <p className="pharagraph">
                Both the user and admin dashboard of the launchpad are integrated with numerous features, robust security protocols, and other relevant functionalities. We design the launchpad’s user dashboard with advanced-level navigating features and with user-friendly plugins.
                </p>
              </div>
              <div className="square">
                <h3 className="befor">UI/UX Interface</h3>
                <p className="pharagraph">
                The important factor for seeking the user's attention is done through the attractive and contentful UI/UX design. We use superior Front-end and back-end tools for designing the website. We focus on delivering the best launchpad website that satisfies our clients.
                </p>
              </div>
              <div className="square" >
                <h3 className="befor">Testing & Debugging</h3>
                <p className="pharagraph">
                Once after the successful development stage, our testers and technicians test the launchpad for positive results. The testing process is conducted on various tiers to ensure the quality of the launchpad. Then, you can launch the launchpad without any fear.
                </p>
              </div>
            </div>
            <div className="d-lg-flex four">
              <div className="square" >
                <h3 className="befor">Token Distribution </h3>
                <p className="pharagraph">
                When the launchpad is deployed successfully, the new tokens of the upcoming crowdfunding projects are distributed. Depending on the startup’s need, pre token sale event can also be conducted. Determined funds are raised through token sales for numerous projects.
                </p>
              </div>
              <div className="square" >
                <h3 className="befor">Support and Assistance</h3>
                <p className="pharagraph">
                Even after delivering the product, we aid with 24/7 assistance to rectify technical errors, server problems, and other queries instantly. Depending on the advancements, we help our clients upgrade the launchpad. 
                </p>
              </div>
            </div>
            <div className="mt-3 text-center">
                <ButtonComponent />
            </div>
          </div>
        </div>
      </section>

    )
  }
}

export default ProcessOf
