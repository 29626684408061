import React from "react"

class BenifitsOf extends React.Component {

  render() {
    return (

      <section className="process-of white-wallet benifits pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">
              <span className="bluecolor">Key Benefits</span> of Blockchain Development for Businesses
            </h2>
            <div className="d-lg-flex flex-wrap">
              <div className="square" >
                <h3>Decentralization</h3>
                <p className="pharagraph">
                Blockchain's decentralized nature eliminates single points of failure, enhances data security, and optimizes blockchain resources without relying on a central authority. This provides you with a complete sense of control and security across all business categories.
                </p>
              </div>
              <div className="square">
                <h3>Transparency </h3>
                <p className="pharagraph">
                Blockchain's transparent and tamper-proof nature builds trust among users by allowing them to access transaction details freely. This ensures accountability and fosters greater user engagement by providing a secure, open environment for all participants.
                </p>
              </div>
              <div className="square" >
                <h3>Smart Contracts</h3>
                <p className="pharagraph"> 
                Smart contracts are self-executing programs that automate blockchain operations securely and without the need for supervision. They enhance platform operational efficiency by streamlining processes and reducing human intervention, as defined by their code.
                </p>
              </div>
              <div className="square" >
                <h3>Improved Efficiency</h3>
                <p className="pharagraph">
                Blockchain’s enhanced efficiency enables faster transaction processing and reduces operational delays. Its decentralized nature removes intermediaries, streamlining processes and lowering costs, while ensuring accuracy and reducing the risk of errors.
                </p>
              </div>
              <div className="square" >
                <h3>Data Security</h3>
                <p className="pharagraph">
                Another prominent advantage is data security. Each transaction is validated using advanced cryptography techniques and consensus algorithms, ensuring the integrity of the data. Blockchain networks maintain this integrity by keeping the data immutable.
                </p>
              </div>
              <div className="square" >
                <h3>Interoperability</h3>
                <p className="pharagraph">
                The interoperability feature allows the blockchain platform to easily communicate with other blockchain networks. This promotes expanded collaboration between different systems which gives you boasted business opportunities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BenifitsOf
