import React from "react"
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
//import Slider from "react-slick";

class WeFocused extends React.Component {

  render() {

    // var settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //   };

    return (

      <section className="we-focus">
          <div className="container"> 
            <h2 className="heading-h2 text-center">Blockchain Development Services <span className="bluecolor">We Focus</span>
            </h2>
            <p className="text-center">
            Unlock the potential of blockchain technology with Coinsclone's innovative solutions designed with the motto of reliable and guaranteed performance. Discover how our diverse blockchain development services can drive your success.
            </p>
            <Tab.Container id="left-tabs-example" defaultActiveKey="start" transition={false}>
                <Row>
                    <Col xl={4} lg={12} className="p-0 choose-tab">
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                        <Nav.Link eventKey="start">1. Public Blockchain Development</Nav.Link>
                        <Nav.Link eventKey="first">2. Private Blockchain Development</Nav.Link>
                        <Nav.Link eventKey="second">3. Blockchain Consulting Services</Nav.Link>
                        <Nav.Link eventKey="third">4. Custom Blockchain Development</Nav.Link>
                        <Nav.Link eventKey="four">5. White Label Blockchain Solutions</Nav.Link>
                        <Nav.Link eventKey="five">6. Smart Contract Development</Nav.Link>
                        <Nav.Link eventKey="six">7. dApp Development</Nav.Link>
                        <Nav.Link eventKey="seven">8. Enterprise Blockchain Development</Nav.Link>
                        <Nav.Link eventKey="eight">9. Blockchain-as-a-Service</Nav.Link>
                        </Nav.Item>
                    </Nav>  
                    </Col>
                    <Col xl={8} lg={12} className="contents"> 
                    <Tab.Content>
                        <Tab.Pane eventKey="start"> 
                            <h3>Public Blockchain Development</h3>
                            <p>Our Blockchain Developers specialize in Public Blockchain Development to help businesses harness the power of decentralized networks.  Here, we offer an open, permissionless framework, enabling anyone to participate and access the network, ensuring trust and immutability. Our expert team designs scalable, secure, and cost-effective public blockchain solutions tailored to meet the unique needs of your business.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="first"> 
                            <h3>Private Blockchain Development</h3>
                            <p>Our Private Blockchain Development services are designed to provide businesses with secure, permissioned networks.  Only authorized participants are given access to validate the network, offering greater control over data privacy and security. Coinsclone offers private blockchain solutions, tailored to your business needs while maintaining strict governance and compliance standards.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <h3>Blockchain Consulting Services</h3>
                            <p>Coinsclone, a leading blockchain development company, with a team of expert Blockchain Consultants will simplify your blockchain journey. These experts will assist in navigating through the complexities of blockchain technology with expertise. From delivering strategic business advisories and integrating advanced features to customizing smart contracts, our consultants provide comprehensive, reliable solutions. </p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <h3>Custom Blockchain Development</h3>
                            <p>Coinsclone, a premier custom blockchain development company, is your trusted ally. With a team of expert Blockchain Consultants, we specialize in creating tailored blockchain solutions that align perfectly with your business needs. We provide custom blockchain solutions for more than 10+ industries, from finance to education, ensuring a future-focused approach to meet tomorrow's demands.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="four">
                            <h3>White Label Blockchain Solutions</h3>
                            <p>We offer White Label Blockchain Solutions designed to empower businesses across various industries with scalable, and customizable blockchain technology. Our ready-made solutions can be tailored to fit your specific needs, from supply chain management to education, enabling seamless integration into your existing systems. With our w  hite-label solutions, businesses can quickly deploy blockchain tech rapidly.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="five">
                            <h3>Smart Contract Development</h3>
                            <p>Our team of professional developers excels in crafting smart contracts that bring automation, security, and transparency to your project. We specialize in developing tailored smart contracts, boasting eminent blockchains like Ethereum, Polygon, Solana, and more to meet your unique business requirements. With our expertise, you can minimize operational flaws, streamline processes, and gain enhanced access and control.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="six">
                            <h3>dApp Development</h3>
                            <p>Smart contract-powered DApp development could assist you in promoting enterprise standards keeping user privacy and platform simplicity. Powered by leading blockchain networks, our DApps are designed to offer unparalleled security, scalability, and user engagement. Influence the digital ecosystem with our custom dApp development solutions specially created for business innovations, catering to a wide range of industries.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="seven">
                            <h3>Enterprise Blockchain Development</h3>
                            <p>At Coinsclone, we empower businesses with Enterprise Blockchain Development services designed to enhance business operations. By leveraging the power of (DLT) Distributed Ledger Technology, we tailor blockchain solutions that optimize workflows and ensure data integrity across your organization. Coinsclone delivers cutting-edge Enterprise blockchain solutions to keep your business ahead in the digital ecosystem.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="eight">
                            <h3>Blockchain-as-a-Service</h3>
                            <p>We provide Blockchain-as-a-Service (BaaS) to help businesses seamlessly integrate blockchain technology into their business operations. This solution eliminates the complexity of building and maintaining infrastructure, allowing you to focus on your business goals while we handle the tech aspects. With Coinsclone's BaaS, your business can access enhanced and uniquely customized, enterprise-grade blockchain services.</p>
                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
          </div>
      </section>

    )
  }
}

export default WeFocused
