import { StaticImage } from 'gatsby-plugin-image'
import React,{useEffect,useState} from 'react'


const WhatMake = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);



    return (
      <section className="what-make pt-100 mb-0">   
        <div className='gray-bg'>
        <div className="container">
          <h5 className='heading-h2 text-center'>Tools and <span className='bluecolor'>Technologies</span> Powering Our Blockchain Development</h5>

              { isDesktop && (
            <StaticImage src="https://coinsclone.mo.cloudinary.net/images/blockchain/stacks.webp" 
              alt="Technologies use for blockchain development"
              width={1200} />
              )}
              { isMobile && (
            <StaticImage src="https://coinsclone.mo.cloudinary.net/images/workflow/blockchain-mobile.webp" 
              alt="Technologies use for blockchain development"
              width={390} />
              )}
            
        </div>
        </div>
      </section>
    )

}

export default WhatMake