import React from "react"

class Kickoff extends React.Component {

  render() {
    return (

      <section className="benifor kickoff pt-100 mb-0">
          <div className="container"> 
            <h5 className="heading-h2 text-center">
            Get Started Now with Expert Blockchain Developers, <span className="bluecolor">Your Success Awaits !!</span>
            </h5>
            <p className="text-center">
            Take the lead in shaping your business ideas into running successful projects. With our streamlined blockchain development services, increase your potential growth while experiencing amazing benefits.
            </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square">
                <p className="head3">Contact us</p>
                <p className="pharagraph">
                Fill out the form below and submit your proposal. We’re eager to hear your project ideas and bring them to life.
                </p>
              </div>
              <div className="square">
                <p className="head3">Schedule a Consultation</p>
                <p className="pharagraph">
                Connect with our blockchain consultation specialists. Share your goals, and we’ll schedule a call at your convenience to guide you directly.
                </p>
              </div>
              <div className="square">
                <p className="head3">Cost Estimation</p>
                <p className="pharagraph">
                Get your approximate cost estimation and time frame based on your project proposal - Absolutely free of cost!
                </p>
              </div>
              <div className="square">
                <p className="head3">Begin Your Project</p>
                <p className="pharagraph">
                Join hands with us as our reputed client, and leverage our technical expertise to ignite your business vision into reality and kickstart your project!
                </p>
              </div>
            </div>
            <div className='dot-box'>
                <p>Harness Our Custom Blockchain Solutions Today! <span className='d-block'>Inspire And Lead Industries Tomorrow!</span></p>
                <div className='dot-btn d-md-flex justify-content-center'>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn">Free Trial</a>
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a Calendly Meeting</a>
                </div>
              </div>
          </div>
      </section>

    )
  }
}

export default Kickoff
