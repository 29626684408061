import React,  { useEffect, useState } from 'react'
// import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function BestSelling()  {

  const [whatsappLink, setWhatsappLink] = useState('');
    const [whatsappwebLink, setWhatsappwebLink] = useState('');

    useEffect(() => {
      if (typeof window !== 'undefined') {
        const currentUrl = window.location.href;
        const whatsappMessage = `Hi there! I am looking for your Services. Can you send me the Product Demo of ${currentUrl}`;
        const link = `https://api.whatsapp.com/send?phone=919500575285&text=${encodeURIComponent(whatsappMessage)}`;
        setWhatsappLink(link);
      }
      if (typeof window !== 'undefined') {
        const currentwebUrl = window.location.href;
        const whatsappwebMessage = `Hi there! I am looking for your Services. Can you send me the Product Demo of ${currentwebUrl}`;
        const link = `https://web.whatsapp.com/send?phone=919500575285&text=${encodeURIComponent(whatsappwebMessage)}`;
        setWhatsappwebLink(link);
      }
    }, []);


    return (
      <div className="cryptoex pt-100">
        <section className="usr-admin-tab benifor bestsell mb-0">
            <div className="container">
              <p className="heading-h2 text-center">
                <span className="bluecolor">Our Best-Selling</span>{" "}
                <h2 className="heading-h2">
                White Label Crypto Exchange Clone Software
                </h2>
              </p>
              <p className='text-center'>
              Explore our top-rated White Label Crypto Exchange Clone Software, crafted for excellence. Our White label solution offers robust performance and easy integration. Achieve success in the crypto space with our industry-leading technology.
          </p>
              <div className="row">
                <div className="d-lg-flex flex-wrap add-one px-0">
                  <div className="square">
                    <h3>White-Label of Binance</h3>
                    <p className="pharagraph">
                    Binance White-Label solution is a highly productive readymade exchange platform that works similarly to the popular Binance website. You can build a feature-rich Cryptocurrency exchange instantly using this solution
                    </p>
                  </div>
                  <div className="square">
                    <h3>White-Label of ByBit</h3>
                    <p className="pharagraph">
                    Bybit White-Label solution has become one of the popular exchange development software and is frequently acquired by entrepreneurs. It helps to build a secure and user-friendly Cryptocurrency exchange exactly like Bybit.
                    </p>
                  </div>
                  <div className="square">
                    <h3>White-Label of OKX</h3>
                    <p className="pharagraph">
                    Startups looking to thrive in the Cryptocurrency industry can make use of the OKX White- Label software due to its numerous benefits. Currently, OKX ranks as one of the top Cryptocurrency exchanges, and replicating it is a great idea. 
                    </p>
                  </div>
                  <div className="square">
                    <h3>White-Label of Kraken</h3>
                    <p className="pharagraph">
                    Kraken has built a huge trust among Cryptocurrency users already and, starting an exchange similar to Kraken is likely to yield success. The White Label crypto exchange software of Kraken can be a game-changing solution to launch a cryptocurrency exchange. 
                    </p>
                  </div>
                  <div className="square">
                    <h3>White-Label of Paxful</h3>
                    <p className="pharagraph">
                    White-Label Paxful software helps entrepreneurs develop a Cryptocurrency exchange exactly like the existing Paxful exchange. By adopting this exchange software, you can save considerable cost and development time involved. 
                    </p>
                  </div>
                  <div className="square">
                    <h3>White-Label of Coinbase</h3>
                    <p className="pharagraph">
                    White-Label exchange software of Coinbase is a readily built exchange solution for the faster entry of entrepreneurs into the market. Coinbase, the popular OTC platform has diverse revenue streams and you can build a customized exchange with similar options.  
                    </p>
                  </div>
                </div>
              </div>
              <div className='dot-box'>
                <p>Accelerate Your Exchange Business Growth With <span className='d-block'>Our White Label Crypto Exchange Software</span></p>
                <div className='dot-btn d-md-flex justify-content-center'>
                <a className="whtsmodal mobile-hide" rel="noreferrer" target="_blank" href={whatsappwebLink} >
              <span><FontAwesomeIcon icon={faWhatsapp} /></span>
              <span>whatsapp</span>
            </a>
            <a className="whtsmodal mobile-on" rel="noreferrer" target="_blank" href={whatsappLink} >
              <span><FontAwesomeIcon icon={faWhatsapp} /></span>
              <span>whatsapp</span>
            </a>
                {/* <ButtonComponent /> */}
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
              </div>
            </div>
        </section>
      </div>
    )

}

export default BestSelling