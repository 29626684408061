import React,  { useEffect, useState } from 'react'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



function OurVarious() {


  const [whatsappLink, setWhatsappLink] = useState('');
  const [whatsappwebLink, setWhatsappwebLink] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const currentUrl = window.location.href;
      const whatsappMessage = `Hi there! I am looking for your Services. Can you send me the Product Demo of ${currentUrl}`;
      const link = `https://api.whatsapp.com/send?phone=919500575285&text=${encodeURIComponent(whatsappMessage)}`;
      setWhatsappLink(link);
    }
    if (typeof window !== 'undefined') {
      const currentwebUrl = window.location.href;
      const whatsappwebMessage = `Hi there! I am looking for your Services. Can you send me the Product Demo of ${currentwebUrl}`;
      const link = `https://web.whatsapp.com/send?phone=919500575285&text=${encodeURIComponent(whatsappwebMessage)}`;
      setWhatsappwebLink(link);
    }
  }, []);



    return (
      <section className="icon whitelabel-innove pt-100 secureof">
        <div className="container">
          <h3 className="heading-h2 text-center">Our White Label Crypto Wallet <span className='bluecolor'>Development Services</span></h3>
          <p className='text-center'>Discover the various services offered by our White label Crypto Wallet in a unified ecosystem. Whether it's a financial institution, cryptocurrency exchange, or blockchain app, our crypto wallet offers all-rounder-adaptable integration.</p>
          <div className="row">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                API Integration
                  </h3>
                <p className="pharagraph">White label cryptocurrency wallet makes it possible to integrate APIs without any hassle. We allow you to connect your backend infrastructure, enabling smooth communication and data exchange between your platform and our crypto wallet. This empowers you to unlock the full potential of our wallet's features.</p>
              </div>
              <div className='leftdiv'>
                <p className='head3'>
                Exchange Integration
                </p>
                <p className="pharagraph">Immaculately integrate your crypto exchange with our White-label Wallet, providing your users with a straightforward way to make crypto trading. A single interface is provided to manage assets, keep an eye on market prices, and make transactions. This removes the need for multiple logins or platforms.</p>
              </div>
              <div className='leftdiv'>
                <p className='head3'>
                Payment Gateway Integration
                </p>
                <p className="pharagraph">Establish a connection between your payment gateway with our White label Wallet to enable simple and secure payments straight from the wallet Interface. Increase customer comfort and reach by connecting your payment gateway and allowing users to easily transact with cryptos, thus driving customer loyalty.</p>
              </div>
              <div className='leftdiv'>
                <p className='head3'>
                Decentralized Application (DApps) Integration
                </p>
                <p className="pharagraph">Integrate your dApps with our Crypto Wallet to fuel the power of blockchain technology. This unlocks fresh possibilities for your dApps, fostering broader acceptance and elevating the overall end-user experience. This automatically enhances the security and transparency of all the transactions.</p>
              </div>
              <div className='leftdiv'>
                <p className='head3'>
                Third-party service Integration
                </p>
                <p className="pharagraph">Integrate third-party services into White label Crypto Wallets such as identity verification providers, KYC/AML solutions, or analytics platforms. In addition, this embellishes security, meets regulatory requirements, and gains valuable insights into user behavior and transaction patterns, while also enhancing the overall functionality of the wallet.</p>
              </div>
              <div className='leftdiv'>
                <p className='head3'>
                Blockchain Network Integration
                </p>
                <p className="pharagraph">With our White label Wallet, you can incorporate an enormous variety of blockchain networks and communicate with various blockchain protocols. You can offer a diverse spectrum of digital assets, apps, and smart contracts. This increases the user's flexibility, ultimately driving your business growth.</p>
              </div>
              </div>
          </div>
          <div className='dot-box'>
                <p>Ready to Launch Your Custom Crypto Wallet? <span className='d-block'>Let's Turn Your Vision Into Reality</span></p>
                <div className='dot-btn d-md-flex justify-content-center'>
                <a className="whtsmodal mobile-hide" rel="noreferrer" target="_blank" href={whatsappwebLink} >
              <span><FontAwesomeIcon icon={faWhatsapp} /></span>
              <span>whatsapp</span>
            </a>
            <a className="whtsmodal mobile-on" rel="noreferrer" target="_blank" href={whatsappLink} >
              <span><FontAwesomeIcon icon={faWhatsapp} /></span>
              <span>whatsapp</span>
            </a>
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
              </div>
        </div>
      </section>
    )
  
}

export default OurVarious