import React from "react"
import ButtonComponent from "../ButtonComponent/ButtonComponent"

class DevProcess extends React.Component {

  render() {
    return (
      <section className="process-of ico pt-100 mb-0">
            <div className="container">     
              <h2 className="heading-h2 text-center">Our Initial DEX <span className="bluecolor">Offering Services</span> to help you
              </h2>
              <p className="text-center">
              Coinsclone offers a wide variety of premier IDO development services by combining a mix of innovation, cutting-edge technological enhancements, and the fundamentals of blockchain’s core advancements.
              </p>
              <div className="d-lg-flex add-one">
                <div className="square" >
                  <h3 className="befor">IDO Development Consultations</h3>
                  <p className="pharagraph">
                  Our experts and professional consultants are looking forward to hearing your doubts and ideas on IDO Development. Get a grip and a clear understanding of what and how this IDO works and your other doubts regarding IDO Development.
                  </p>
                </div>
                <div className="square">
                  <h3 className="befor">White Paper Services</h3>
                  <p className="pharagraph">
                  With our passionate and expert team, we will help you create white papers for your project with legible descriptions. The Smart contract creation with tailored solutions enables projects to conduct easy fundraising efficiently while rebranding the project for upliftment.
                  </p>
                </div>
                <div className="square" >
                  <h3 className="befor">IDO Launchpad Development</h3>
                  <p className="pharagraph">
                  Our company offers secure and scalable IDO Launchpad Development services for carrying out efficient crowd-fundraising events. This service comes with a complete package from smart contract development to successful platform deployment.
                  </p>
                </div>
              </div>
              <div className="d-lg-flex add-three">
                <div className="square" >
                  <h3 className="befor">Maintenance & Support</h3>
                  <p className="pharagraph">
                  Post-launch support is crucial in the successful journey of every platform. In case of any modifications or additional requirements, our professionals and experts provide your platform with ultimate back-end and needed support.
                  </p>
                </div>
                <div className="square">
                  <h3 className="befor">IDO Token Development</h3>
                  <p className="pharagraph">
                  Our crypto engineers combine blockchain networks like Ethereum, and Tron to craft your IDO tokens that satisfy your business needs. Create suited tokens for your project’s fundraising by grabbing our IDO token development services.
                  </p>
                </div>
                <div className="square" >
                  <h3 className="befor">IDO Token Listing</h3>
                  <p className="pharagraph">
                  Strategizing your business goals and aligning the project’s objective, our consultant team will support you in choosing the suitable and optimal Decentralized exchange platform to list your IDO tokens.
                  </p>
                </div>
              </div>
              <div className="mt-3 text-center">
                  <ButtonComponent />
              </div>
            </div>
      </section>

    )
  }
}

export default DevProcess
