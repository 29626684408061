import React from "react"

class MyriadNiches extends React.Component {

  render() {
    return (

      <section className="process-of myriad pt-100 mb-0">
          <div className="container"> 
            <h2 className="heading-h2 text-center">
            Coinsclone's NFT Marketplace Development based on <span className="bluecolor">Myriad Niches</span>
            </h2>
            <div className="d-lg-flex add-one">
              <div className="square" >
                <p className="head3 bfor">
                 <a href="https://www.coinsclone.com/nft-art-marketplace-development/">NFT Art Marketplace</a>
                  </p>
                <p className="pharagraph">
                We develop an exceptional NFT Art Marketplace platform that allows artists to convert their unique artwork into NFTs and claim ownership. Connect with us to create a thriving NFT Marketplace for art.
                </p>
              </div>
              <div className="square">
                <p className="head3 bfor">
                 <a href="https://www.coinsclone.com/nft-music-marketplace-development/">NFT Music Marketplace</a>
                  </p>
                <p className="pharagraph">
                Our NFT Music Marketplace Platform enables you to trade music-based assets. Rare and unique digital music collectibles can be transformed into NFTs and traded on the platform.
                </p>
              </div>
              <div className="square" >
                <p className="head3 bfor">
                 <a href="https://www.coinsclone.com/nft-sports-marketplace-development/">NFT Sports Marketplace</a>
                  </p>
                <p className="pharagraph">
                Create an NFT Sports Marketplace with our redefined NFT marketplace development services. Our platform enables athletes to monetize their assets and connect with their enthusiastic fans.
                </p>
              </div>
            </div>
            <div className="d-lg-flex add-two">
              <div className="square" >
                <p className="head3 bfor">
                 <a href="https://www.coinsclone.com/celebrity-nft-marketplace-development/">Celebrity NFT Marketplace</a>
                  </p>
                <p className="pharagraph">
                Develop a unique Celebrity NFT Marketplace with our impressive NFT Marketplace solution. This enables celebrities to turn their assets into NFTs for monetization.
                </p>
              </div>
              <div className="square" >
                <p className="head3 bfor">
                 <a href="https://www.coinsclone.com/nft-fashion-marketplace-development/">Fashion NFT Marketplace</a>
                  </p>
                <p className="pharagraph">
                Launch an NFT Marketplace for fashion accessories with the help of our expert NFT Marketplace developers. Elevate your fashion ideas to enhance brand visibility and earn royalties. 
                </p>
              </div>
            </div>
            <div className="d-lg-flex add-three">
              <div className="square" >
                <p className="head3 bfor">
                 <a href="https://www.coinsclone.com/nft-real-estate-marketplace-development/">Real Estate NFT Marketplace</a>
                  </p>
                <p className="pharagraph">
                We provide effective NFT Real Estate Marketplace development services that help you tokenize your real estate properties as NFTs, which can be traded on NFT marketplaces for profit.
                </p>
              </div>
              <div className="square">
                <p className="head3 bfor">
                 <a href="https://www.coinsclone.com/nft-ticketing-marketplace-development/">NFT Ticketing Marketplace</a>
                  </p>
                <p className="pharagraph">
                Build an NFT ticketing marketplace for events that convert real tickets into NFTs, which can be traded later. This unlocks diverse revenue opportunities for event organizers.
                </p>
              </div>
              <div className="square" >
                <p className="head3 bfor">
                 <a href="https://www.coinsclone.com/fractional-nft-marketplace-development/">Fractional NFT Marketplace</a>
                  </p>
                <p className="pharagraph">
                Coinsclone offers a tailored Fractional NFT Marketplace that enables users to buy and sell fractions or shares of NFTs. This platform allows users to attract a diverse pool of investors.
                </p>
              </div>
            </div>
          </div>
      </section>
    )
  }
}

export default MyriadNiches
