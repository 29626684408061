import React,  { useEffect, useState } from 'react'
// import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function OurInnovative()  {

    const [whatsappLink, setWhatsappLink] = useState('');
    const [whatsappwebLink, setWhatsappwebLink] = useState('');

    useEffect(() => {
      if (typeof window !== 'undefined') {
        const currentUrl = window.location.href;
        const whatsappMessage = `Hi there! I am looking for your Services. Can you send me the Product Demo of ${currentUrl}`;
        const link = `https://api.whatsapp.com/send?phone=919500575285&text=${encodeURIComponent(whatsappMessage)}`;
        setWhatsappLink(link);
      }
      if (typeof window !== 'undefined') {
        const currentwebUrl = window.location.href;
        const whatsappwebMessage = `Hi there! I am looking for your Services. Can you send me the Product Demo of ${currentwebUrl}`;
        const link = `https://web.whatsapp.com/send?phone=919500575285&text=${encodeURIComponent(whatsappwebMessage)}`;
        setWhatsappwebLink(link);
      }
    }, []);


    return (

      <section className="blockchn ico pt-100 mb-0">
        <div className="container">
            <h2 className="heading-h2 text-center">Our Innovative Blockchain Solutions for <span className="bluecolor">Future-Ready</span> Enterprises</h2>
          <div className="row mb-3">
                    <div className='d-flex flex-wrap justify-content-center px-0'>
                        <div className='fea-card'>
                            <h3>
                              <a href='https://www.coinsclone.com/cryptocurrency-exchange-development-company/'>Crypto Exchange Trading Platform</a>
                            </h3>
                            <p className='pharagraph'>Build a smart and unique crypto exchange platform with advanced features to provide seamless trading experiences targeting every type of user. With our solutions, you can create centralized, decentralized, and hybrid exchanges, designed for speed, security, and efficiency.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>
                              <a href='https://www.coinsclone.com/cryptocurrency-wallet-development-company/'>Cryptocurrency Wallet Solutions</a>
                            </h3>
                            <p className='pharagraph'>We offer custom cryptocurrency wallet development, providing users with secure and user-friendly wallets to store, and transact digital assets. From multi-currency wallets to hardware wallet integrations, our solutions are packed with quality features and security.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>
                              <a href='https://www.coinsclone.com/cryptocurrency-payment-gateway-development/'>Crypto Payment Gateway</a>
                            </h3>
                            <p className='pharagraph'>Our crypto payment software enables seamless and secure cryptocurrency payment acceptance on your platform. This allows businesses to accept multiple cryptocurrencies with ease, ensuring secure, instant, and cross-border transactions with minimal fees globally.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>
                              <a href='https://www.coinsclone.com/defi-development-company/'>DeFi Solutions</a>
                            </h3>
                            <p className='pharagraph'>Begin your DeFi journey with our tailored DeFi solutions featuring from DEX platforms to lending and borrowing platforms. We create decentralized applications (dApps) that provide transparency, security, and autonomy for users eliminating the need for intermediaries.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>
                              <a href='https://www.coinsclone.com/nft-marketplace-development/'>NFT Marketplace</a>
                            </h3>
                            <p className='pharagraph'>Launch your own NFT marketplace with Coinsclone's readymade solutions, where users can trade digital assets effortlessly. Our marketplace software is customizable, secure, and designed to handle high transaction volumes efficiently, empowering both digital artists and collectors.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>
                              <a href='https://www.coinsclone.com/cryptocurrency-trading-bot-development/'>Crypto Trading Bot</a>
                            </h3>
                            <p className='pharagraph'>Our developers craft intelligent, high-performing trading bots powered by advanced pre-coded algorithms. These bots operate 24/7 and execute trades based on real-time market analysis. This innovative solution minimizes risks and enables you to capitalize on market fluctuations.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>
                              <a href='https://www.coinsclone.com/crypto-token-development/'>Crypto Token</a>
                            </h3>
                            <p className='pharagraph'>Create your own crypto token with our custom token development services. Whether it's for ICOs, smart contracts, or your decentralized application, we help you design and deploy tokens on leading blockchains and platforms based on your requirements. </p>
                        </div>
                        <div className='fea-card'>
                            <h3>Crowdfunding Platform</h3>
                            <p className='pharagraph'>Develop a secure and transparent blockchain-based crowdfunding platform for your next fundraising project. We offer ICO, STO, and IEO solutions that simplify tokenized fundraising, giving startups and investors a trustworthy space to collaborate and raise capital.</p>
                        </div>
                    </div>
          </div>
          <div className='dot-box'>
                <p>Ready to Unlock the Potential of Blockchain With Our Expert Developers? <span className='d-block'>You are Just a Click Away!</span></p>
                <div className='dot-btn d-md-flex justify-content-center'>
                <a className="whtsmodal mobile-hide" rel="noreferrer" target="_blank" href={whatsappwebLink} >
              <span><FontAwesomeIcon icon={faWhatsapp} /></span>
              <span>whatsapp</span>
            </a>
            <a className="whtsmodal mobile-on" rel="noreferrer" target="_blank" href={whatsappLink} >
              <span><FontAwesomeIcon icon={faWhatsapp} /></span>
              <span>whatsapp</span>
            </a>
                {/* <ButtonComponent /> */}
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
              </div>
        </div>
      </section>

    )
}

export default OurInnovative
