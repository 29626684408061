import React from 'react'


const OurCustom = () => {


  return (
    <section className="customs pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Our Custom</span> Blockchain Development Process
            </h2>
            <div className='d-flex flex-wrap justify-content-center top'>
                <div className='custom-blk'>
                    <p className="head3">Define</p>
                    <p>Get started on your journey by defining the project's mission and vision. Here we make requirements ready, prepare strategies to implement, determine target users, and their demands, and conceptualize the project’s flow.</p>
                </div>
                <div className='custom-blk'>
                    <p className="head3">Design</p>
                    <p>Here, we make sure that the platform design looks sleek, and ensure that the layout and color pattern look appealing to catch users' attention. Furthermore, to keep the user engaged, we align the aesthetics of the platform with your business goals.</p>
                </div>
                <div className='custom-blk'> 
                    <p className="head3">Develop</p>
                    <p>We follow a structured approach, carefully tracking each development phase. Our expert team provides guidance at every stage, using the necessary tools to verify and optimize the development process for maximum efficiency and quality.</p>
                </div>
            </div>
            <div className='d-flex flex-wrap justify-content-center bottom'>
                <div className='custom-blk'>
                    <p className="head3">Debug</p>
                    <p>This is another crucial stage, where we test your blockchain’s efficiency and performance with designated tools to ensure flaw-free functionality. To maintain the security and credibility of the blockchain network, we periodically supervise and upgrade.</p>
                </div>
                <div className='custom-blk'>
                    <p className="head3">Deploy</p>
                    <p>Our expert developer team will prepare the technical requirements and strategic outline to execute the plan. We will align the necessary resources to deploy while, ensuring it meets your enterprise goals and is ready for a successful launch.</p>
                </div>
            </div>
        </div>
        <a href="/contact-us/" className="bluebtn mt-3 mx-auto d-table">Get a Free Demo</a>
      </div>
    </section>
  )
}

export default OurCustom